import { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import axiosInstance from '@/utils/axiosInstance';
import 'swiper/css';
import 'swiper/css/pagination';
import './CustomSwiper.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { LoadingBox, LoadingScreen } from '@/metronic-dashboard/layout/components/Loading_Error';
import { convertNumsToFa } from '@/utils/numLangChanger'

import Main_Nav from "../../components/main-nav/Main_Nav";
import Footer from "../../components/footer/Footer";
import CommentsSlider from './CommentsSlider';
import bgLight from "../../assets/image/bg-light.png";
import bgLight2 from "../../assets/image/light6.png";
import bg2 from "../../assets/image/bg-2-min.png";
import bg2sm from "../../assets/image/bg-2-sm-min.png";
import meditation from "../../assets/svg/meditation.svg";
import nextBtn from "../../assets/svg/nextBtn.svg";
import backGround from "../../assets/img/back3.jpeg";
import backGround2 from "../../assets/img/back4.jpeg";

import backMobile from "../../assets/img/backMobile.jpeg";
import CountdownTimer from './CountdownTimer';
import CountdownTimer2 from './CountdownTimer2';


import ChatIcon from "../../assets/svg/solar_chat-unread-bold.svg";
import StarIcon from "../../assets/svg/Star.svg";

const Home = () => {
    const [bgImage, setBgImage] = useState('');
    const [myloading, setmyLoading] = useState(true);
    const [error, setError] = useState(null);

    const container = useRef();
    const [loading, setLoading] = useState(true)
    const [nav_DropDown, setNav_DropDown] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const [sliderData, setSliderData] = useState([]);
    const [repeatedSliderData, setRepeatedSliderData] = useState([]); // New state for repeated data

    const [commentsData, setCommentsData] = useState();
    const [activeIndex, setActiveIndex] = useState(0);

    const quickAccess = useRef(null)


    useEffect(() => {
        const fetchBackgroundImage = async () => {
            try {
                const response = await axiosInstance.get('/imagehome'); // Replace with your actual API endpoint
                const data = response.data;

                // Assuming your API returns the image URL in the format you showed
                // You might need to construct the full URL depending on your backend setup
                const imageUrl = data.image;
                setBgImage(imageUrl);
                setmyLoading(false);
            } catch (err) {
                console.error('Error fetching background image:', err);
                setError(err);
                setmyLoading(false);
            }
        };

        fetchBackgroundImage();
    }, []);

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <div>Error loading page</div>;
    // }


    const scrollHandler = () => {
        if (quickAccess.current) {
            const { top } = quickAccess.current.getBoundingClientRect();
            const offset = 100;
            window.scrollTo({
                top: window.scrollY + top - offset,
                behavior: 'smooth'
            });

            if (swiperRef.current && swiperRef.current.swiper) {
                const slideIndex = sliderData.findIndex(item => item.id === 1);
                if (slideIndex !== -1) {
                    swiperRef.current.swiper.slideToLoop(slideIndex, 300, true);
                }
            }
        }
    }

    const close_Popup = e => {
        const dropdownElement = document.querySelector('.nav-dropdown');
        if (nav_DropDown && dropdownElement && !dropdownElement.contains(e.target)) setNav_DropDown(false);
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/website/quick_access');
                if (response.data.status === 1) {
                    const originalData = [...response.data.data].reverse();
                    setSliderData(originalData);
                    // Create repeated data array by concatenating the original data 3 times
                    setRepeatedSliderData([...originalData, ...originalData, ...originalData]);
                }
                setLoading(false)
            } catch (error) {
                console.error('Error fetching slider data:', error);
            }
        };

        fetchData();

        const fetchDataComments = async () => {
            try {
                const response = await axiosInstance.get('/website/sample_comments');
                if (response.data.status === 1) {
                    setCommentsData(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching comments data:', error);
            }
        };

        fetchData();
        fetchDataComments();
    }, []);


    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex);
    };

    const swiperRef = useRef(null);

    const extractComments = (data) => {
        const allComments = [];
        Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
                allComments.push(...data[key]);
            }
        });
        return allComments;
    };

    useEffect(() => {
        convertNumsToFa()
    }, [sliderData, commentsData, loading])

    const comments = commentsData && extractComments(commentsData).map((review, index) => (
        <div key={review.id} className={`comment-box ${index % 2 === 0 ? '' : 'left'} px-9 py-5 bg-[#ECECEC] text-[#616161] mx-4 rounded-[2.7rem] min-h-[167px] sm:min-h-[143px]`}>
            <div className='flex justify-between mb-2'>
                <b className='text-[#454545] text-[21px]'>{review.user.full_name}</b>
                <div className='flex gap-[4px]'>
                    {[...Array(5)].map((_, i) => (
                        <img
                            key={i}
                            src={StarIcon}
                            width={14}
                            style={{ display: i < review.points ? 'block' : 'none' }}
                        />
                    ))}
                </div>
            </div>
            <p className='m-0 leading-6 font-semibold text-[18px] text-justify'>{review.comment.length > 400 ? review.comment.slice(0, 400).concat('...') : review.comment}</p>
        </div>
    )) || [];


    return (
        <section ref={container} id='home' className=' flex flex-col relative bg-white' onClick={close_Popup}>
            <LoadingScreen loading={!sliderData} />

            <Main_Nav
                nav_DropDown={nav_DropDown}
                setNav_DropDown={setNav_DropDown}
                fromHomePage={true}
            />
            <div className='absolute inset-0 z-0 w-screen' ></div>

            {!isMobile &&
                <div id='bg-po' className='h-[calc(100vh+100px)] min-h-[900px] max-w-screen max-h-[1000px] relative overflow-hidden' style={{ background: `url(${backGround}) center no-repeat`, backgroundSize: 'cover' }}>

                    <div className="my-container w-full h-full absolute z-[1] bottom-0 left-1/2 -translate-x-1/2">

                        <div className='absolute w-full z-[1]  right-[140px] lg:top-[600px] lg:text-black'>
                            <div className="flex flex-col items-start">
                                <CountdownTimer targetDate="2025-01-06T23:59:59" /> {/* Adjust the date as needed */}
                            </div>
                        </div>
                    </div>
                    <div className='lg:hidden absolute bottom-0 w-full h-[400px]' style={{ background: 'linear-gradient(0deg, rgb(2 2 2 / 75%) -35%, transparent)' }}></div>
                </div>
            }

            {isMobile &&
                <div
                    className='h-[calc(100vh+100px)] min-h-[800px] relative overflow-hidden bg-cover bg-center bg-no-repeat'
                    style={{
                        backgroundImage: `url(${backMobile})`,
                    }}
                >
                    <div className="w-full h-full z-[1]">
                        <div className='absolute w-full z-[1] lg:text-black bottom-[140px]'>

                            <div className="flex flex-col items-center mx-2 sm:mx-4">
                                <CountdownTimer2 targetDate="2025-01-06T23:59:59" /> {/* Adjust the date as needed */}
                            </div>
                            <div className="flex flex-col items-center mx-1">

                                <div className="bg-white/90 rounded-lg p-2 sm:p-3 min-w-[60px] sm:min-w-[120px]">
                                    <div className="text-2xl sm:text-2xl font-bold text-gray-950 text-center">
                                        <a href="/course/99">شروع همراهی
                                        </a>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div className='lg:hidden absolute bottom-0 w-full h-[400px]' style={{ background: 'linear-gradient(0deg, rgb(2 2 2 / 75%) -35%, transparent)' }}></div>
                </div>
            }


            <div id='quick-access-container' className='relative z-[1]'>
                {!isMobile && <img src={bg2} className='w-full h-[calc(100%+340px)] relative my-[-160px] mt-[-150px]' />}
                {isMobile && <img src={bg2sm} className='w-full h-[calc(100%+100px)] relative mt-[-50px]' />}

                <div className='absolute inset-10'>
                    <div ref={quickAccess} className='flex flex-col gap-5 items-center lg:mt-[-70px] -mt-10'>
                        <img src={meditation} width={70} />
                        <h3 className='text-[30px] max-lg:text-[26px] font-semibold'>دسترسی سریع</h3>
                        <p className='text-[20px] mt-[-20px] mx-5 text-center leading-[1.4]'>جهت ورود به بخش مورد نظر خود، بر روی آن کلیک کنید.</p>
                    </div>
                    {loading ?
                        <LoadingBox height={400} />
                        :
                        <>
                            <Swiper
                                ref={swiperRef}
                                slidesPerView="auto"
                                spaceBetween={0}
                                centeredSlides={false}
                                loop={true}
                                loopAdditionalSlides={repeatedSliderData.length} // Add additional slides for smooth looping
                                pagination={{ clickable: true }}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Navigation, Pagination]}
                                onSlideChange={handleSlideChange}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        slidesPerGroup: 1,
                                        spaceBetween: 10,
                                    },
                                    390: {
                                        slidesPerView: 1,
                                        slidesPerGroup: 1,
                                        spaceBetween: 10,
                                    },
                                    450: {
                                        slidesPerView: 1,
                                        slidesPerGroup: 1,
                                        spaceBetween: 10,
                                    },
                                    500: {
                                        slidesPerView: 1,
                                        slidesPerGroup: 1,
                                        spaceBetween: 10,
                                    },
                                    560: {
                                        slidesPerView: 1,
                                        slidesPerGroup: 1,
                                        spaceBetween: 10,
                                    },
                                    640: {
                                        slidesPerView: 1, slidesPerGroup: 1,
                                        spaceBetween: 10,
                                    },
                                    700: {
                                        slidesPerView: 2.35,
                                        spaceBetween: 15,
                                    },
                                    768: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 15,
                                    },
                                    850: {
                                        slidesPerView: 2.8,
                                        spaceBetween: 15,
                                    },
                                    900: {
                                        slidesPerView: 3,
                                        spaceBetween: 15,
                                    },
                                    1024: {
                                        slidesPerView: 3.2,
                                        spaceBetween: 15,
                                    },
                                    1100: {
                                        slidesPerView: 3.5,
                                        spaceBetween: 15,
                                    },
                                    1200: {
                                        slidesPerView: 3.8,
                                        spaceBetween: 15,
                                    },
                                    1300: {
                                        slidesPerView: 4.3,
                                        spaceBetween: 15,
                                    },
                                    1500: {
                                        slidesPerView: 4.5,
                                        spaceBetween: 15,
                                    },
                                    1600: {
                                        slidesPerView: 5,
                                        spaceBetween: 15,
                                    },
                                    1700: {
                                        slidesPerView: 5.4,
                                        spaceBetween: 15,
                                    },
                                    1800: {
                                        slidesPerView: 6,
                                        spaceBetween: 15,
                                    },
                                    2000: {
                                        slidesPerView: 6.5,
                                        spaceBetween: 15,
                                    },
                                    2300: {
                                        slidesPerView: 7,
                                        spaceBetween: 15,
                                    },
                                }}
                            >
                                {repeatedSliderData.map((item, index) => (
                                    <SwiperSlide key={`${item.id}-${index}`} className={index === activeIndex ? 'active' : ''}>
                                        <Link to={item.url} className='block bg-white rounded-2xl p-3 pb-4 transition mb-10' style={{ transform: 'scale(0.95)' }}>
                                            <img src={item.image} className='rounded-xl mb-4 w-full object-cover h-[291px]' alt={item.title_fa} />
                                            <div className='flex flex-col text-[#424242] items-center text-center'>
                                                <h4 className='text-[#212121] text-[25px] max-lg:text-[24px] font-bold'>{item.title_fa}</h4>
                                                <p className='text-[19px] max-lg:text-[18px] font-medium'>{item.full_info}</p>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="flex gap-3 max-lg:hidden absolute right-32 -bottom-12 z-[1]">
                                <img src={nextBtn} width={30} className='cursor-pointer opacity-90 hover:opacity-100' onClick={() => swiperRef.current.swiper.slidePrev()} />
                                <img src={nextBtn} width={30} className='rotate-180 cursor-pointer opacity-40 hover:opacity-50' onClick={() => swiperRef.current.swiper.slideNext()} />
                            </div>
                        </>
                    }
                </div>
            </div>

            <div id='commentsBox' className='bg-white md:pb-6 max-md:pb-12 max-md:px-5 md:px-10 max-lg:mt-[-100px]'>
                <div className='flex flex-col gap-5 items-center mt-48 mb-10 text-[#212121]'>
                    <img src={ChatIcon} width={48} />
                    <h3 className='text-[30px] max-lg:text-[26px] font-semibold text-[#212121]'>نظر همراهان این نقطه</h3>
                    <p className='font-normal text-[20px] mt-[-20px] text-center leading-[1.4]'>همواره بهترین راهنما و روشنگر مسیر ما بوده است.</p>
                </div>
                <CommentsSlider commentsData={commentsData} />
            </div>

            <Footer />
        </section >
    )
}

export default Home;